import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import { graphql } from 'gatsby';
import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
  PremiumQuery,
} from '../../../graphql-types';

import SEO from '@components/seo';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import Container from '@src/components/Container';
import Grid, { Row } from '@src/components/Grid';
import SmsDownloadForm from '@src/components/SmsDownloadForm/SmsDownloadForm';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import Card, { CardImage } from '@src/components/Card';
import RockerTouchCard from '@src/components/RockerTouchCard';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import UspList, { UspListItem } from '@src/components/UspList/UspList';

import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import { AppDownloadUrl } from '@src/pageConstants';
import * as ThirdPartyTracking from '../../lib/thirdPartyTracking';
import paketPages from '@src/data/paketPages';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import { GatsbyImage } from 'gatsby-plugin-image';
import Badge from '@src/components/Badge/Badge';
import Illustration from '@src/components/Illustration/Illustration';

type PremiumProps = {
  data: PremiumQuery;
};

function Premium({ data }: PremiumProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
      pageContent,
      heroImage,
    },
  } = data;

  const uspList = (pageContent[0] as ContentfulTextList).advancedTextListItems;

  const isMobile = useIsMobile();

  const cardImage = useContentfulImage(heroImage);

  const touchDialog = useDialogRoute('touch');
  const touchDialogData = pageContent[1] as ContentfulDialogContent;

  const dlg2 = useDialogRoute('kvitton');
  const dlg2Card = pageContent[2] as ContentfulCard;
  const dlg2Data = pageContent[3] as ContentfulDialogContent;
  const receiptImg = useContentfulImage(dlg2Card.backgroundImage);

  const dlg3 = useDialogRoute('splitta');
  const dlg3Card = pageContent[4] as ContentfulCard;
  const dlg3Data = pageContent[5] as ContentfulDialogContent;
  const fixedCostsImg = useContentfulImage(dlg3Card.backgroundImage);

  const dlg4 = useDialogRoute('cashback');
  const dlg4Card = pageContent[6] as ContentfulCard;
  const dlg4Data = pageContent[7] as ContentfulDialogContent;
  const cashbackImg = useContentfulImage(dlg4Card.backgroundImage);

  const dlg5 = useDialogRoute('spara');
  const dlg5Card = pageContent[8] as ContentfulCard;
  const dlg5Data = pageContent[9] as ContentfulDialogContent;
  const saveImg = useContentfulImage(dlg5Card.backgroundImage);
  const heroCard = pageContent[10] as ContentfulCard;

  return (
    <Layout subNavLinks={paketPages}>
      <SEO title={headLine} />
      <Container style={{ marginTop: 52 }}>
        <Grid spacedRows>
          <TextHero
            title={pageHeader}
            largeSubtitle
            subtitle={pageHeaderSubtitle}
            leftAligned
          >
            <div style={{ marginTop: '-0.2rem', marginBottom: '2rem' }}>
              <Badge color="green-soft">6 månader gratis (värde 594 kr)</Badge>
            </div>
            {pageHeaderDescription}
            <div style={{ marginTop: 32 }}>
              <UspList theme="purple" items={uspList as UspListItem[]} />
            </div>
            {isMobile && (
              <PrimaryButton
                style={{ marginTop: 32 }}
                href={AppDownloadUrl}
                onClick={ThirdPartyTracking.cardSignup}
              >
                Skaffa Premium
              </PrimaryButton>
            )}
          </TextHero>
          {!isMobile && (
            <Card centerContent backdropImage={cardImage} noBorder>
              <SmsDownloadForm
                formProps={{ redirectToAppstore: true }}
                title="Skaffa Rocker Premium idag"
              />
            </Card>
          )}
          <Row>
            <Card
              title={heroCard.title}
              text={heroCard.subtitle}
              contentfulImg={useContentfulImage(heroCard.backgroundImage)}
              theme="purple"
              cta={heroCard.linkText}
              onClick={touchDialog.openDialog}
              wide
            />
          </Row>
          <Card
            title={dlg2Card.title}
            text={dlg2Card.subtitle}
            cta={dlg2Card.linkText}
            onClick={dlg2.openDialog}
            theme="soft"
            noBorder
          >
            <CardImage>
              <div style={{ padding: 24 }}>
                <GatsbyImage
                  image={receiptImg.imageData}
                  alt={receiptImg.title}
                />
              </div>
            </CardImage>
          </Card>
          <Card
            title={dlg3Card.title}
            text={dlg3Card.subtitle}
            cta={dlg3Card.linkText}
            onClick={dlg3.openDialog}
            theme="purple"
            backdropImage={fixedCostsImg}
          />
          <Card
            title={dlg4Card.title}
            text={dlg4Card.subtitle}
            cta={dlg4Card.linkText}
            ctaHref={dlg4Card.linkPath}
            style={{ position: 'relative', overflow: 'hidden' }}
            backdropContent={
              <>
                <Illustration
                  name="Cash1"
                  size={125}
                  style={{ position: 'absolute', left: '78%', top: '34%' }}
                />

                <Illustration
                  name="Cash2"
                  size={145}
                  style={{ position: 'absolute', left: 40, top: '46%' }}
                />

                <Illustration
                  name="Cash3"
                  size={100}
                  style={{ position: 'absolute', left: '79%', top: '81%' }}
                />
              </>
            }
          >
            <CardImage imageData={cashbackImg} />
          </Card>
          <Card
            title={dlg5Card.title}
            text={dlg5Card.subtitle}
            cta={dlg5Card.linkText}
            onClick={dlg5.openDialog}
          >
            <CardImage imageData={saveImg} />
          </Card>
        </Grid>
      </Container>

      <CardPackageInfo />

      <ContentfulDialog
        visible={touchDialog.isOpen}
        onClose={touchDialog.closeDialog}
        contentfulData={touchDialogData}
      />

      <ContentfulDialog
        visible={dlg2.isOpen}
        onClose={dlg2.closeDialog}
        contentfulData={dlg2Data}
      />
      <ContentfulDialog
        visible={dlg3.isOpen}
        onClose={dlg3.closeDialog}
        contentfulData={dlg3Data}
      />
      <ContentfulDialog
        visible={dlg4.isOpen}
        onClose={dlg4.closeDialog}
        contentfulData={dlg4Data}
      />
      <ContentfulDialog
        visible={dlg5.isOpen}
        onClose={dlg5.closeDialog}
        contentfulData={dlg5Data}
      />
    </Layout>
  );
}

export default Premium;

export const query = graphql`
  query Premium($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/paket/premium" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 512)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulFaqSection {
          id
          title
          paragraph {
            paragraph
          }
          questions {
            question
            title
            overline
            answer {
              answer
            }
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulTextSection {
              title
              content {
                content
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
